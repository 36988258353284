module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-glamor/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tomas.Tech","short_name":"Tomas.Tech","description":"Modern web applications and marketing websites for ambitious companies","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","orientation":"portrait","display":"minimal-ui","icon":"static/media/tomas-tech-mark.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://f8c6ae1e980d474b873c4de254b358a2@sentry.io/1500588","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
